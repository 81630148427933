/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'tachyons/css/tachyons.css';
// You can delete this file if you're not using it

// You can delete this file if you're not using it

import wrapWithProvider from './wrap-with-provider'

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = wrapWithProvider
